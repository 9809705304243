import { Container, NormalText } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import BackgroundBigRect from '../../components/BackgroundBigReact'
import BackgroundSmallRect from '../../components/BackgroundSmallRect'
import {
  MAX_MEDIUM_SCREEN_WIDTH,
  MAX_SMALL_IPAD_SCREEN_WIDTH,
  MAX_SMALL_SCREEN_WIDTH,
} from '../GlobalStyles'

/////////////////////////
// styled components
/////////////////////////
const BackgroundContainer = styled.div`
  position: relative;
  margin-top: 52px;
  margin-bottom: 24px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-top: 48px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-top: 36px;
    margin-bottom: 48px;
  }
`

const TitleContainer = styled(Container)`
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    align-items: center;
  }
`
const Title = styled(NormalText)`
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    width: 200px;
    text-align: center;
  }
`
const CustomBackgroundBigRect = styled(BackgroundBigRect)`
  top: -220px;
  right: -270px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    top: -180px;
    right: -254px;
  }
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    right: -202px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    top: -86px;
    right: -208px;
  }
`
const CustomBackgroundSmallRect = styled(BackgroundSmallRect)`
  top: -186px;
  left: -114px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    top: -172px;
    left: -86px;
  }
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    top: -104px;
    left: -52px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    top: -18px;
    left: -46px;
  }
`

/////////////////////////
// React class
/////////////////////////
const NewsletterTitle: React.SFC = () => (
  <BackgroundContainer>
    <CustomBackgroundSmallRect />
    <CustomBackgroundBigRect />
    <TitleContainer>
      <Title className="hero-title">Newsletter Archives</Title>
    </TitleContainer>
  </BackgroundContainer>
)
export default NewsletterTitle
