import { TagEdge } from '@curvo/apollo'
import { Flex } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import { MAX_SMALL_SCREEN_WIDTH } from '../GlobalStyles'
import { TagComponent } from '.'

/////////////////////////
// styled components
/////////////////////////
const PaddingBox = styled.div`
  display: content;
  margin-right: 16px;
  margin-bottom: 16px;
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 6px;
    margin-right: 6px;
  }
`

/////////////////////////
// component classes
/////////////////////////
type Prop = {
  data: TagEdge[]
  returnTag?: (selectedTagID: string | undefined) => void
}
type State = {
  selectedTagID: string | undefined
}

class TagsContainer extends React.Component<Prop, State> {
  readonly state = {
    selectedTagID: undefined,
  }
  getSelectedTag = (tagID: string) => {
    let newTagID: string | undefined
    if (this.state.selectedTagID === tagID) {
      newTagID = undefined
    } else {
      newTagID = tagID
    }
    this.setState({ selectedTagID: newTagID })
    if (this.props.returnTag) {
      this.props.returnTag(newTagID)
    }
  }
  handleRemoveFilter = () => {
    this.setState({ selectedTagID: undefined })
  }
  render() {
    const { data } = this.props
    const { selectedTagID } = this.state
    return (
      <Flex flexDirection="column" marginBottom="40px">
        {data && !!data.length && (
          <Flex flexWrap="wrap">
            {data.map((tag, tagIndex) => (
              <PaddingBox key={'tagContainer' + tagIndex}>
                <TagComponent
                  active={tag.node.id === selectedTagID}
                  data={tag.node}
                  returnOnClick={this.getSelectedTag}
                />
              </PaddingBox>
            ))}
          </Flex>
        )}
      </Flex>
    )
  }
}

export default TagsContainer
