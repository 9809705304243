import { TextInput } from '@curvo/common-ui'
import { debounce } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import iconSearch from '../../images/Icon/Search.svg'

/////////////////////////
// styled components
/////////////////////////
const SearchBox = styled.div`
  position: relative;
  width: 100%;

  input {
    padding: 20px 40px 20px 80px;
    min-height: 64px;
    height: 100%;
  }
  div {
    height: 100%;
  }
`
const SearchBoxIcon = styled.img`
  position: absolute;
  width: 24px;
  left: 40px;
  top: calc(50% - 12px);
`

/////////////////////////
// component class
/////////////////////////
type Props = {
  onChangeText: (newText: string | undefined) => void
}
class NewsSearch extends React.Component<Props> {
  handleChangeText = debounce((newText: string | undefined) => {
    this.props.onChangeText(newText)
  }, 500)
  render() {
    return (
      <SearchBox>
        <TextInput
          height="100%"
          showError={false}
          placeholder="Search"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            this.handleChangeText(event.target.value)
          }
        />
        <SearchBoxIcon src={iconSearch} />
      </SearchBox>
    )
  }
}

export default NewsSearch
