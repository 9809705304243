import React from 'react'
import styled from 'styled-components'

import { Flex, NormalText, MarginProps, Colors } from '@curvo/common-ui'
import { Tag } from '@curvo/apollo'
import { MAX_SMALL_SCREEN_WIDTH } from '../GlobalStyles'

/////////////////////////
// styled components
/////////////////////////
const XSymbol = styled(NormalText)`
  display: none;
  &.selected {
    display: flex;
  }
  :hover {
    color: ${Colors.Secondary};
  }
`
const Container = styled(Flex)`
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: pointer;

  border-radius: 50vh;
  border: 1px solid;
  border-color: ${Colors.Black};
  background-color: ${Colors.White};

  transition: border-color, background-color, width;
  transition-duration: 0.15s;

  &.active {
    border-color: ${Colors.Primary};
    background-color: ${Colors.Primary};
  }
  :hover {
    border-color: ${Colors.Primary};
  }
  :hover ${XSymbol} {
    color: ${Colors.Warning};
  }

  padding: 10px 24px;
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    padding: 10px 16px;
  }
`

/////////////////////////
// component classes
/////////////////////////
type Props = MarginProps & {
  data: Tag
  active?: boolean
  returnOnClick: (tag: string) => void
}
class TagComponent extends React.Component<Props> {
  handleOnClick = () => {
    this.props.returnOnClick(this.props.data.id)
  }
  render() {
    const { data, active } = this.props

    return (
      <Container
        margin={this.props.margin}
        marginTop={this.props.marginTop}
        marginBottom={this.props.marginBottom}
        marginLeft={this.props.marginLeft}
        marginRight={this.props.marginRight}
        onClick={this.handleOnClick}
        className={active ? 'active' : ''}
      >
        <NormalText white={active} marginRight="20px">
          {data.name}
        </NormalText>
        <NormalText secondary={!active} white={active}>
          {data.newsletterCount ? data.newsletterCount : '0'}
        </NormalText>
        <XSymbol white className={active ? 'selected' : ''} marginLeft="1em">
          x
        </XSymbol>
      </Container>
    )
  }
}

export default TagComponent
