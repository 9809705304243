import { CategoryEdge } from '@curvo/apollo'
import { ClickedOutside, Colors, Flex, NormalText, Toggle } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import greyDownArrow from '../../images/Arrow/Grey-down.svg'
import { MAX_MEDIUM_SCREEN_WIDTH, MAX_SMALL_IPAD_SCREEN_WIDTH } from '../GlobalStyles'

/////////////////////////
// styled components
/////////////////////////
const CategorySelector = styled(Flex)`
  position: relative;
  justify-content: center;

  margin-right: 24px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-right: 16px;
  }
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    margin-right: 0px;
    margin-bottom: 12px;
  }
`
const SelectorButton = styled(Flex)`
  align-items: center;
  justify-content: center;
  cursor: pointer;

  background-color: white;
  transition: background-color 0.25s;
  border-radius: 4px;
  overflow: hidden;

  :active {
    background-color: #d8d8d8;
  }

  border: 1px solid #d0d9dc;
  padding: 0.5em;

  min-height: 64px;
  min-width: 200px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    min-height: 56px;
    min-width: 172px;
  }
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    min-height: 48px;
    min-width: 100%;
  }
`
const CategorySelectorDropdown = styled(Flex)`
  position: absolute;
  flex-direction: column;
  z-index: 1;

  top: 100%;
  left: 0;
  min-width: 100%;

  background: #ffffff;
  box-shadow: 4px 6px 16px 1px rgba(0, 0, 0, 0.1);

  visibility: hidden;
  overflow: hidden;
  opacity: 0;

  &.active {
    visibility: visible;
    opacity: 1;
    overflow-y: auto;
    animation: dropdownAppear 0.15s ease-in-out;
  }
  animation: dropdownHide 0.15s ease-in-out;
`
const CategorySelectorDropdownItem = styled(Flex)`
  justify-content: center;
  align-items: center;
  cursor: pointer;

  height: 80px;

  background-color: ${Colors.White};
  letter-spacing: 0.72px;
  transition: background-color, color 0.25s;

  :hover {
    background-color: #d8d8d8;
  }
  &.selected {
    background-color: ${Colors.Primary};
  }
`

/////////////////////////
// component class
/////////////////////////
type Prop = {
  category: string | undefined
  categoriesList: CategoryEdge[]
  onSelect: (type: string | undefined) => void
}
class NewsCategorySelector extends React.Component<Prop> {
  initButton = true
  initDropdown = true

  handleDropdownDisplay = (isActive: boolean) => {
    if (this.initDropdown) {
      this.initDropdown = false
      return 'preload'
    }
    return isActive ? 'active' : ''
  }
  findSelectedCategoryName = () => {
    const { category, categoriesList } = this.props
    const result = categoriesList.find(categoryEdge => {
      return categoryEdge.node.id === category
    })
    return result ? result.node.name : 'Category'
  }

  render() {
    const { category, categoriesList, onSelect } = this.props
    return (
      <Toggle shouldOffWhenEsc>
        {({ isActive, toggle, off }) => (
          <CategorySelector>
            <SelectorButton onClick={toggle}>
              <NormalText className="heading" marginRight="0.5em" center>
                {category && categoriesList ? this.findSelectedCategoryName() : 'Category'}
              </NormalText>
              <img src={greyDownArrow} />
            </SelectorButton>
            <ClickedOutside shouldHandleClick={isActive} onClickOutside={off}>
              {({ ref }) => (
                <CategorySelectorDropdown
                  ref={ref}
                  className={this.handleDropdownDisplay(isActive)}
                >
                  {category !== undefined && (
                    <CategorySelectorDropdownItem
                      onClick={() => {
                        onSelect(undefined)
                        off()
                      }}
                    >
                      <NormalText className="medium-title">Remove filter</NormalText>
                    </CategorySelectorDropdownItem>
                  )}
                  {categoriesList &&
                    !!categoriesList.length &&
                    categoriesList.map((type, index) => (
                      <CategorySelectorDropdownItem
                        key={'sort-type' + index}
                        className={category === type.node.id ? 'selected' : ''}
                        onClick={() => {
                          onSelect(type.node.id)
                          off()
                        }}
                      >
                        <NormalText className="medium-title" white={category === type.node.id}>
                          {type.node.name}
                        </NormalText>
                      </CategorySelectorDropdownItem>
                    ))}
                </CategorySelectorDropdown>
              )}
            </ClickedOutside>
          </CategorySelector>
        )}
      </Toggle>
    )
  }
}
export default NewsCategorySelector
