import styled from 'styled-components'
import {
  MAX_SMALL_IPAD_SCREEN_WIDTH,
  MAX_SMALL_SCREEN_WIDTH,
  zIndex,
} from '../components/GlobalStyles'
import bgSmallRect from '../images/CommonBackground/bg-small-rect.svg'

const BG_SMALL_RECT_SIZE = 228
const BG_SMALL_RECT_SIZE_M = 128
const BG_SMALL_RECT_SIZE_S = 90

const BackgroundSmallRect = styled.div`
  position: absolute;
  z-index: ${zIndex.back};

  background: url(${bgSmallRect}) no-repeat center;

  background-size: ${BG_SMALL_RECT_SIZE}px;
  width: ${BG_SMALL_RECT_SIZE}px;
  height: ${BG_SMALL_RECT_SIZE}px;
  top: 268px;
  left: -${BG_SMALL_RECT_SIZE / 2}px;
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    background-size: ${BG_SMALL_RECT_SIZE_M}px;
    width: ${BG_SMALL_RECT_SIZE_M}px;
    height: ${BG_SMALL_RECT_SIZE_M}px;
    top: 192px;
    left: -${BG_SMALL_RECT_SIZE_M / 2}px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    background-size: ${BG_SMALL_RECT_SIZE_S}px;
    width: ${BG_SMALL_RECT_SIZE_S}px;
    height: ${BG_SMALL_RECT_SIZE_S}px;
    top: 300px;
    left: -${BG_SMALL_RECT_SIZE_S / 2}px;
  }
`

export default BackgroundSmallRect
