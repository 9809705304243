import { Newsletter } from '@curvo/apollo'
import { Colors, Flex, NormalText } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import env from '../../config/env'
import iconTag from '../../images/Tag.svg'
import { MAX_SMALL_SCREEN_WIDTH } from '../GlobalStyles'

/////////////////////////
// styled components
/////////////////////////
const Container = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  transition: border-color 0.25s;

  background: #ffffff;
  border: 1px solid ${Colors.Secondary};
  :hover {
    border-color: ${Colors.Primary};
  }

  min-height: 250px;
  height: 250px;
  width: 100%;
  padding: 0 24px;
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    padding: 0 20px;
  }
`
const NewsBody = styled(NormalText)`
  align-items: flex-start;
  overflow-y: auto;
  height: 96px;
  margin-bottom: 18px;
  white-space: pre-wrap;
`
const TagsContainer = styled(Flex)`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  bottom: 0;
  left: 0;
  padding: 18px 0;
  border-top: 1px solid #d0d9dc;

  img {
    margin-right: 14px;
    height: 16px;
  }
`

/////////////////////////
// component class
/////////////////////////
type Props = {
  data: Newsletter
}
const NewsTab: React.SFC<Props> = props => {
  const data = props.data
  const { id, name, description } = data

  return (
    <Container>
      <a href={`${env.WEBAPP_LINK}download/newsletter/${id}`} target="_blank">
        <Flex flexDirection="column">
          <NormalText className="small-title" margin="16px 0 14px 0">
            {name ? name : 'No Title'}
          </NormalText>
          <NewsBody secondary>{description ? description : 'No Description'}</NewsBody>
        </Flex>
        <TagsContainer>
          {data.tags && !!data.tags.length ? (
            <React.Fragment>
              <img src={iconTag} />
              {data.tags.map((tag, index) => {
                return (
                  <NormalText key={'tag' + tag.id} secondary marginRight="0.5em">
                    {data.tags && index === data.tags.length - 1 ? tag.name : tag.name + ','}
                  </NormalText>
                )
              })}
            </React.Fragment>
          ) : null}
        </TagsContainer>
      </a>
    </Container>
  )
}

export default NewsTab

// {!!props.tags.length &&
//   props.tags.map((tag, tagIndex) => {
//     return (tagIndex ? ', ' : '') + tag
//   })}
