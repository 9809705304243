import { GroupByEnum } from '@curvo/apollo'
import { ClickedOutside, Colors, Flex, NormalText, Toggle } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import greyDownArrow from '../../images/Arrow/Grey-down.svg'
import { MAX_MEDIUM_SCREEN_WIDTH, MAX_SMALL_IPAD_SCREEN_WIDTH } from '../GlobalStyles'

/////////////////////////
// styled components
/////////////////////////
const SortBySelector = styled(Flex)`
  position: relative;
  justify-content: center;

  margin-right: 24px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-right: 16px;
  }
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    margin-right: 0px;
    margin-bottom: 12px;
  }
`
const SelectorButton = styled(Flex)`
  align-items: center;
  justify-content: center;
  cursor: pointer;

  background-color: white;
  transition: background-color 0.25s;

  border: 1px solid #d0d9dc;
  border-radius: 4px;
  padding: 0.5em;

  :active {
    background-color: #d8d8d8;
  }

  min-width: 300px;
  min-height: 64px;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    min-width: 272px;
    min-height: 56px;
  }
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    min-width: 100%;
    min-height: 48px;
  }
`
const SortBySelectorDropdown = styled(Flex)`
  position: absolute;
  flex-direction: column;
  z-index: 1;

  background: #ffffff;
  box-shadow: 4px 6px 16px 1px rgba(0, 0, 0, 0.1);

  left: 0;
  top: 100%;
  min-width: 100%;

  visibility: hidden;
  overflow: hidden;
  opacity: 0;

  &.active {
    opacity: 1;
    visibility: visible;
    animation: dropdownAppear 0.15s ease-in-out;
  }
  animation: dropdownHide 0.15s ease-in-out;
`
const SortBySelectorDropdownItem = styled(Flex)`
  justify-content: center;
  align-items: center;
  cursor: pointer;

  height: 80px;

  background-color: ${Colors.White};
  letter-spacing: 0.72px;
  transition: background-color, color 0.25s;

  :hover {
    background-color: #d8d8d8;
  }

  &.selected {
    background-color: ${Colors.Primary};
  }
`

/////////////////////////
// component class
/////////////////////////
type Prop = {
  sortType: string
  onSelect: (type: string) => void
}
class NewsSortBySelector extends React.Component<Prop> {
  initDropdown = true
  handleDropdownDisplay = (isActive: boolean) => {
    if (this.initDropdown) {
      this.initDropdown = false
      return 'preload'
    }
    return isActive ? 'active' : ''
  }

  render() {
    const { sortType, onSelect } = this.props
    const sortTypeLabel = Object.keys(GroupByEnum).find(
      key => GroupByEnum[key as keyof typeof GroupByEnum] === sortType,
    )

    return (
      <Toggle shouldOffWhenEsc>
        {({ isActive, toggle, off }) => (
          <SortBySelector>
            <SelectorButton onClick={toggle}>
              <NormalText className="heading" marginRight="0.5em" center>
                Group By: {sortTypeLabel}
              </NormalText>
              <img src={greyDownArrow} />
            </SelectorButton>
            <ClickedOutside shouldHandleClick={isActive} onClickOutside={off}>
              {({ ref }) => (
                <SortBySelectorDropdown ref={ref} className={this.handleDropdownDisplay(isActive)}>
                  {!!Object.keys(GroupByEnum).length &&
                    Object.keys(GroupByEnum).map((type, index) => (
                      <SortBySelectorDropdownItem
                        key={'sort-type' + index}
                        className={sortType === type ? 'selected' : ''}
                        onClick={() => {
                          onSelect(GroupByEnum[type as keyof typeof GroupByEnum])
                          off()
                        }}
                      >
                        <NormalText className="medium-title" white={sortType === type}>
                          {type}
                        </NormalText>
                      </SortBySelectorDropdownItem>
                    ))}
                </SortBySelectorDropdown>
              )}
            </ClickedOutside>
          </SortBySelector>
        )}
      </Toggle>
    )
  }
}
export default NewsSortBySelector
