import { GroupByEnum, NewsletterConnection, NewsletterEdge } from '@curvo/apollo'
import React from 'react'
import NewsTabsGroup, { NewsTabGroupType } from './NewsTabsGroup'

const MONTH: { [number: string]: string } = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
}

type Props = {
  newsletters: NewsletterConnection
  sortBy: GroupByEnum
}
type State = {
  isLoading: boolean
}
class NewsPage extends React.Component<Props, State> {
  readonly state = {
    isLoading: false,
  }

  private groupBy(items: NewsletterEdge[]) {
    if (this.props.sortBy === GroupByEnum.Date) {
      return items.reduce(this.groupByPublishDateReducer, [])
    } else {
      return items.reduce(this.groupByCategoryReducer, [])
    }
  }

  private grouping = (
    groups: NewsTabGroupType[],
    newMember: NewsletterEdge,
    groupName: string,
    issue: string | null | undefined = '',
  ) => {
    const findIndex = groups.findIndex(group => {
      return group.groupName === groupName
    })
    if (findIndex >= 0) {
      groups[findIndex].news.push(newMember.node)
    } else {
      groups.push({
        groupName,
        groupNameSub: issue,
        news: [newMember.node],
      })
    }
  }
  private groupByPublishDateReducer = (
    accumulator: NewsTabGroupType[],
    curNews: NewsletterEdge,
  ) => {
    const { publishMonth, publishYear, issue } = curNews.node

    const groupName = `${publishMonth && MONTH[publishMonth]} ${publishYear}`
    this.grouping(accumulator, curNews, groupName, issue)

    return accumulator
  }

  private groupByCategoryReducer = (accumulator: NewsTabGroupType[], curNews: NewsletterEdge) => {
    const { category } = curNews.node
    if (category) {
      this.grouping(accumulator, curNews, category.name)
    } else {
      this.grouping(accumulator, curNews, 'Uncategorized')
    }

    return accumulator
  }

  render() {
    const { newsletters } = this.props
    return (
      <React.Fragment>
        {newsletters &&
          newsletters.edges &&
          !!newsletters.edges.length &&
          this.groupBy(newsletters.edges).map((newsGroup, index) => (
            <NewsTabsGroup key={'newsGroup' + index} data={newsGroup} />
          ))}
      </React.Fragment>
    )
  }
}

export default NewsPage
