import styled from 'styled-components'
import {
  MAX_SMALL_IPAD_SCREEN_WIDTH,
  MAX_SMALL_SCREEN_WIDTH,
  zIndex,
} from '../components/GlobalStyles'
import bgBigRect from '../images/CommonBackground/bg-big-rect.svg'

const BG_BIG_RECT_SIZE = 542
const BG_BIG_RECT_SIZE_M = 308
const BG_BIG_RECT_SIZE_S = 266

const BackgroundBigRect = styled.div`
  position: absolute;
  z-index: ${zIndex.back};

  background: url(${bgBigRect}) no-repeat center;

  background-size: ${BG_BIG_RECT_SIZE}px;
  width: ${BG_BIG_RECT_SIZE}px;
  height: ${BG_BIG_RECT_SIZE}px;
  top: -218px;
  right: -286px;
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    background-size: ${BG_BIG_RECT_SIZE_M}px;
    width: ${BG_BIG_RECT_SIZE_M}px;
    height: ${BG_BIG_RECT_SIZE_M}px;
    top: -152px;
    right: -218px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    background-size: ${BG_BIG_RECT_SIZE_S}px;
    width: ${BG_BIG_RECT_SIZE_S}px;
    height: ${BG_BIG_RECT_SIZE_S}px;
    top: -170px;
    right: -164px;
  }
`

export default BackgroundBigRect
