import { Newsletter } from '@curvo/apollo'
import { Flex, NormalText } from '@curvo/common-ui'
import React from 'react'
import styled from 'styled-components'
import blueDownArrow from '../../images/Arrow/Blue-down.svg'
import {
  MAX_MEDIUM_SCREEN_WIDTH,
  MAX_SMALL_IPAD_SCREEN_WIDTH,
  MAX_SMALL_SCREEN_WIDTH,
} from '../GlobalStyles'
import { NewsTab } from '.'

/////////////////////////
// styled components
/////////////////////////
const CustomContainer = styled(Flex)`
  flex-direction: column;
  margin-bottom: 56px;
  flex-shrink: 0;
  @media screen and (max-width: ${MAX_MEDIUM_SCREEN_WIDTH}px) {
    margin-bottom: 48px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 40px;
  }
`
const HeaderComponent = styled(Flex)`
  flex-direction: column;

  margin-bottom: 32px;
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-bottom: 24px;
  }
`
const Title = styled(NormalText)`
  padding-left: 22px;
  height: 28px;
  border-left: #0076fd 4px solid;
`
const NewsTabsContainer = styled(Flex)<{ length: number }>`
  width: 100%;
  flex-wrap: wrap;

  margin-right: -32px;
  margin-bottom: -32px;

  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    margin-right: -20px;
    margin-bottom: -20px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-right: -16px;
    margin-bottom: -16px;
  }
`
const NewTabsWrapper = styled.div`
  width: calc(25% - 32px);
  margin-right: 32px;
  margin-bottom: 32px;

  @media screen and (max-width: 1440px) {
    width: calc(33% - 32px);
  }
  @media screen and (max-width: ${MAX_SMALL_IPAD_SCREEN_WIDTH}px) {
    width: 320px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    width: 300px;
    margin-right: 16px;
    margin-bottom: 16px;
  }
`

const LoadMoreContainer = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  margin-top: 32px;
  cursor: pointer;
  @media screen and (max-width: ${MAX_SMALL_SCREEN_WIDTH}px) {
    margin-top: 24px;
  }

  &.hide {
    display: none;
  }
`
/////////////////////////
// component class
/////////////////////////
export interface NewsTabGroupType {
  groupName: string | null | undefined
  groupNameSub: string | null | undefined
  news: Newsletter[]
}

type Props = {
  data: NewsTabGroupType
}
type State = {
  newsLength: number
}

export default class NewsTabsGroup extends React.Component<Props, State> {
  readonly state = {
    newsLength: 6,
  }

  onLoadMoreClicked = () => {
    this.setState({ newsLength: this.state.newsLength + 3 })
  }

  render() {
    const { groupName, groupNameSub, news } = this.props.data
    const { newsLength } = this.state
    return (
      <React.Fragment>
        <CustomContainer>
          <HeaderComponent>
            <Title className="heading">{groupName}</Title>
            <NormalText secondary paddingLeft="28px">
              {groupNameSub}
            </NormalText>
          </HeaderComponent>
          {!!news.length && (
            <NewsTabsContainer length={news.length}>
              {news.slice(0, newsLength).map(newsTab => (
                <NewTabsWrapper>
                  <NewsTab key={'NewsTab_' + newsTab.id} data={newsTab} />
                </NewTabsWrapper>
              ))}
            </NewsTabsContainer>
          )}
          <LoadMoreContainer
            className={newsLength >= news.length ? 'hide' : ''}
            onClick={this.onLoadMoreClicked}
          >
            <img src={blueDownArrow} />
            <NormalText primary marginLeft="0.5em">
              Show 3 more sections
            </NormalText>
          </LoadMoreContainer>
        </CustomContainer>
      </React.Fragment>
    )
  }
}
